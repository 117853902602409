import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import App from './App.vue'
import store from './store'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default-dark.css'
import '../Viewport.scss'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueMaterial)


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
