<template>
  <div class="page-container md-layout-column">
    <md-app>
      <md-app-toolbar>
        <md-button
          class="md-icon-button"
          @click="toggleMenu"
          v-if="!showNavigation"
        >
          <md-icon>menu</md-icon>
        </md-button>
        <span class="md-title">Rust Breeding Calculator</span>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="showNavigation" md-persistent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Menu</span>
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <navigation-bar></navigation-bar>
      </md-app-drawer>

      <md-app-content>
        <div class="md-layout md-alignment-top-left mainDiv">
          <div
            class="
              md-layout md-gutter md-layout-item md-alignment-center-left
              left-div
            "
          >
            <div class="md-layout-item geneButtons">
              <gene-select></gene-select>
            </div>
            <div class="md-layout-item save-clear-button">
              <div>
                <md-button class="md-raised md-primary" @click="savePlant">
                  Save
                </md-button>
              </div>
              <div>
                <md-button class="md-raised md-accent" @click="resetGenes">
                  Clear
                </md-button>
              </div>
            </div>
            <div class="md-layout-item sim-button">
              <md-button class="md-raised" @click="automate">
                Automate
              </md-button>
            </div>
            <div class="md-layout-item">
              <plant-genes
                v-for="(plant, index) in plants"
                v-bind:key="plant.id"
                v-bind:gene="plant"
                v-bind:index="index"
              ></plant-genes>
            </div>
          </div>
          <div class="md-layout-item">
            <automatic-pairs
              class="best-gene"
              v-if="bestGene.groupPlants.length > 0"
              v-bind:gene="bestGene.result"
              v-bind:pairs="bestGene.groupPlants"
              v-bind:pairType="'Best-Pair'"
            ></automatic-pairs>

            <automatic-pairs
              v-for="group in results"
              v-bind:key="group[0].id"
              v-bind:gene="group[0].result"
              v-bind:pairs="group[0].groupPlants"
              v-bind:pairType="group[0].groupPlants.length + '-Pair'"
            ></automatic-pairs>
          </div>
        </div>
      </md-app-content>
    </md-app>
  </div>
</template>


<script>
import PlantGenes from "../components/PlantGenes.vue";
import AutomaticPairs from "../components/AutomaticPairs.vue";
import NavigationBar from "../components/NavigationBar.vue";
import GeneSelect from "../components/GeneSelect.vue";

export default {
  name: "automaticPage",
  components: {
    PlantGenes,
    AutomaticPairs,
    NavigationBar,
    GeneSelect,
  },
  data: () => ({
    showNavigation: true,
    firstSelect: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
    },
    bestGene: {
      groupPlants: [],
      result: {
        gene1: "",
        gene2: "",
        gene3: "",
        gene4: "",
        gene5: "",
        gene6: "",
      },
    },
    GENE_COUNT: 6,
    MAX_PAIRS: 9,
    results: [],
    optimalGene: ["G", "G", "G", "Y", "Y", "Y"],
  }),
  methods: {
    toggleMenu() {
      this.showNavigation = !this.showNavigation;
    },
    geneSelect(key, value) {
      switch (key) {
        case 1:
          this.geneObj["gene1"] = value;
          break;
        case 2:
          this.geneObj["gene2"] = value;
          break;
        case 3:
          this.geneObj["gene3"] = value;
          break;
        case 4:
          this.geneObj["gene4"] = value;
          break;
        case 5:
          this.geneObj["gene5"] = value;
          break;
        case 6:
          this.geneObj["gene6"] = value;
          break;
      }
    },
    renderGene(index, gene) {
      let curGene = "gene" + index;
      if (this.geneObj[curGene] == gene || this.geneObj[curGene] == "") {
        return true;
      } else {
        return false;
      }
    },
    savePlant() {
      this.$store.commit("saveSelectGene");
      this.automate();
    },
    resetGenes() {
      this.$store.commit("resetSelectGene");
    },
    simulateBreeding(inputArr) {
      let geneKeys = ["gene1", "gene2", "gene3", "gene4", "gene5", "gene6"];
      let result = {
        gene1: "",
        gene2: "",
        gene3: "",
        gene4: "",
        gene5: "",
        gene6: "",
      };
      for (var i = 0; i < geneKeys.length; i++) {
        result[geneKeys[i]] = this.calcGeneStrength(geneKeys[i], inputArr);
      }
      return result;
    },
    calcGeneStrength(gene, inputArr) {
      let geneArr = [];
      for (var j = 0; j < inputArr.length; j++) {
        let plant = inputArr[j];
        geneArr.push(plant[gene]);
      }
      let geneCount = {};
      for (var i = 0; i < geneArr.length; i++) {
        if (geneArr[i] == "W" || geneArr[i] == "X") {
          geneCount[geneArr[i]] = Number(
            (Math.round((1 + (geneCount[geneArr[i]] || 0)) * 10) / 10).toFixed(
              1
            )
          );
        } else {
          geneCount[geneArr[i]] = Number(
            (
              Math.round((0.6 + (geneCount[geneArr[i]] || 0)) * 10) / 10
            ).toFixed(1)
          );
        }
      }
      let maxVal = 0;
      let maxKey = "";
      for (var obj in geneCount) {
        if (geneCount[obj] > maxVal) {
          maxKey = obj.toString();
          maxVal = geneCount[obj];
        } else if (geneCount[obj] == maxVal) {
          maxKey += "/" + obj;
        }
      }
      return maxKey;
    },
    automate() {
      this.results = [];
      for (var i = 1; i < this.plants.length && i < this.MAX_PAIRS; i++) {
        this.results.push(this.k_combinations(this.plants, i + 1));
      }
      this.results = this.convertCombinations(this.results);
      this.sortByScore(this.results);

      if (this.results.length > 0) {
        let best;
        for (var j = 0; j < this.results.length; j++) {
          //iterate through sub-array
          best = this.getBestResultInSub(this.results[j]); //get best plant gene TODO:OPTIMIZE (skip rest if score lower)
          this.results[j].splice(this.results[j].indexOf(best), 1); //remove it from array
          this.results[j].unshift(best); //place it at front
        }
        this.bestGene = JSON.parse(
          JSON.stringify(this.getBestResult(this.results))
        );
      }
    },
    convertCombinations(array) {
      let res = [];
      for (let i = 0; i < array.length; i++) {
        //iterate through different k-pairs-type
        res.push([]);
        for (let j = 0; j < array[i].length; j++) {
          //iterate through combinations of k-length
          let group = {
            groupPlants: array[i][j],
            result: this.simulateBreeding(array[i][j]),
            score: 0,
          };
          group.score = this.calculateScore(group.result);
          res[i].push(group);
        }
      }
      return res;
    },
    k_combinations(set, k) {
      // https://gist.github.com/axelpale/3118596
      var i, j, combs, head, tailcombs;

      // There is no way to take e.g. sets of 5 elements from
      // a set of 4.
      if (k > set.length || k <= 0) {
        return [];
      }

      // K-sized set has only one K-sized subset.
      if (k == set.length) {
        return [set];
      }

      // There is N 1-sized subsets in a N-sized set.
      if (k == 1) {
        combs = [];
        for (i = 0; i < set.length; i++) {
          combs.push([set[i]]);
        }
        return combs;
      }
      combs = [];
      for (i = 0; i < set.length - k + 1; i++) {
        // head is a list that includes only our current element.
        head = set.slice(i, i + 1);
        // We take smaller combinations from the subsequent elements
        tailcombs = this.k_combinations(set.slice(i + 1), k - 1);
        // For each (k-1)-combination we join it with the current
        // and store it to the set of k-combinations.
        for (j = 0; j < tailcombs.length; j++) {
          combs.push(head.concat(tailcombs[j]));
        }
      }
      return combs;
    },
    sortByScore(array) {
      for (var i = 0; i < array.length; i++) {
        array[i].sort(function (a, b) {
          return b.score - a.score;
        });
      }
    },
    calculateScore(inputObj) {
      let res = 0;
      for (var i = 0; i < this.GENE_COUNT; i++) {
        let geneKey = "gene" + (i + 1);
        if (
          inputObj[geneKey].includes("G") ||
          inputObj[geneKey].includes("Y") ||
          inputObj[geneKey].includes("H")
        ) {
          res++;
        }
      }
      return res;
    },
    getBestResult(array) {
      let max = array[0][0];
      let curGene;
      for (var i = 0; i < array.length; i++) {
        curGene = array[i][0];
        if (curGene.score > max.score) {
          //more greens in current gene
          max = curGene;
        } else if (curGene.score == max.score) {
          //same amount of green genes
          if (
            this.matchToOptimal(this.optimalGene, curGene.result) >
            this.matchToOptimal(this.optimalGene, max.result)
          ) {
            //more matches to optimal gene
            max = curGene;
          } else if (
            this.matchToOptimal(this.optimalGene, curGene.result) ==
            this.matchToOptimal(this.optimalGene, max.result)
          ) {
            //same amount of matches to optimal gene
            if (
              this.countSlashes(curGene.result) < this.countSlashes(max.result)
            ) {
              //less slashes (50/50, 33/33/33) [the less, the better]
              max = curGene;
            }
          }
        }
      }
      return max;
    },
    getBestResultInSub(array) {
      let max = array[0];
      for (var i = 0; i < array.length; i++) {
        let curGene = array[i];
        /* console.log("Score: ", curGene.score, max.score);
        console.log("Optimal: ", this.matchToOptimal(this.optimalGene, curGene.result), this.matchToOptimal(this.optimalGene, max.result));
        console.log("Slashes: ", this.countSlashes(curGene.result), this.countSlashes(max.result)); */
        if (curGene.score > max.score) {
          //more greens in current gene
          max = curGene;
        } else if (curGene.score == max.score) {
          //same amount of green genes
          if (
            this.matchToOptimal(this.optimalGene, curGene.result) >
            this.matchToOptimal(this.optimalGene, max.result)
          ) {
            //more matches to optimal gene
            max = curGene;
          } else if (
            this.matchToOptimal(this.optimalGene, curGene.result) ==
            this.matchToOptimal(this.optimalGene, max.result)
          ) {
            //same amount of matches to optimal gene
            if (
              this.countSlashes(curGene.result) < this.countSlashes(max.result)
            ) {
              //less slashes (50/50, 33/33/33) [the less, the better]
              max = curGene;
            }
          }
        }
      }
      return max;
    },
    matchToOptimal(optimal, other) {
      let arr = optimal.slice();
      for (var gene in other) {
        for (var k = 0; k < arr.length; k++) {
          //console.log(other[gene], " INCLUDES " , arr[k], " ", other[gene].includes(arr[k])); //DEBUG
          if (other[gene].includes(arr[k])) {
            arr.splice(k, 1);
            k = 100;
          }
        }
      }
      return this.GENE_COUNT - arr.length;
    },
    countSlashes(geneObj) {
      let count = 0;
      for (var gene in geneObj) {
        if (geneObj[gene].includes("/")) {
          count++;
        }
      }
      return count;
    },
    geneToString(plantObj) {
      let res = "";
      for (var gene in plantObj) {
        res += gene;
      }
      return res;
    },
  },
  computed: {
    geneObj() {
      return this.$store.state.geneSelect;
    },
    plants() {
      return this.$store.state.savedPlants;
    },
  },
};
</script>


<style lang="scss" scoped>
.md-app {
  min-height: 940px;
  border: 1px solid rgba(#000, 0.12);
}

.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-content {
  padding: 16px;
}

.nav-icon {
  margin-right: 0px;
}

.gene-button {
  height: 60px;
  width: 60px;
  font-size: 20px;
  margin-bottom: 10px;
}

.geneButtons {
  max-width: 460px;
  margin-right: 40px;
}

.mainDiv {
  max-width: 99%;
}

.save-clear-button {
  padding: 0px !important;
}

.sim-button {
  padding: 0px !important;
}

.best-gene {
  margin-bottom: 1em;
}

.left-div {
  max-width: 730px;
}
</style>