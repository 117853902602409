<template>
  <div class="page-container md-layout-column">
    <md-app>
      <md-app-toolbar>
        <md-button
          class="md-icon-button"
          @click="toggleMenu"
          v-if="!showNavigation"
        >
          <md-icon>menu</md-icon>
        </md-button>
        <span class="md-title">Rust Breeding Calculator</span>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="showNavigation" md-persistent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Breeding</span>
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <navigation-bar></navigation-bar>
      </md-app-drawer>

      <md-app-content>
        <div class="md-layout md-gutter md-alignment-center-left mainDiv">
          <div class="md-layout-item geneButtons">
            <gene-select></gene-select>
          </div>
          <div class="md-layout-item sim-button">
            <div>
              <md-button class="md-raised md-primary" @click="savePlant">
                Save
              </md-button>
            </div>
            <div>
              <md-button class="md-raised md-accent" @click="resetGenes">
                Clear
              </md-button>
            </div>
          </div>
          <div class="md-layout-item sim-button">
            <md-button class="md-raised" @click="simulateBreeding">
              Simulate
            </md-button>
          </div>
          <div class="md-layout-item">
            <result-gene
              v-bind:key="resultGene.id"
              v-bind:gene="resultGene"
            ></result-gene>
          </div>
        </div>
        <plant-genes
          v-for="plant in plants"
          v-bind:key="plant.id"
          v-bind:gene="plant"
        ></plant-genes>
      </md-app-content>
    </md-app>
  </div>
</template>


<script>
import GeneSelect from '../components/GeneSelect.vue';
import NavigationBar from "../components/NavigationBar.vue";
import PlantGenes from "../components/PlantGenes.vue";
import ResultGene from "../components/ResultGene.vue";

export default {
  name: "startPage",
  data: () => ({
    showNavigation: true,
    firstSelect: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
    },
    resultGene: {
      gene1: "",
      gene2: "",
      gene3: "",
      gene4: "",
      gene5: "",
      gene6: "",
    },
  }),
  components: {
    PlantGenes,
    ResultGene,
    NavigationBar,
    GeneSelect,
  },
  methods: {
    toggleMenu() {
      this.showNavigation = !this.showNavigation;
    },
    savePlant() {
      this.$store.commit("saveSelectGene");
      this.simulateBreeding();
    },
    resetGenes() {
      this.$store.commit("resetSelectGene");
    },
    simulateBreeding() {
      let geneKeys = ["gene1", "gene2", "gene3", "gene4", "gene5", "gene6"];
      for (var i = 0; i < geneKeys.length; i++) {
        this.resultGene[geneKeys[i]] = this.calcGeneStrength(geneKeys[i]);
      }
    },
    calcGeneStrength(gene) {
      let geneArr = [];
      for (var j = 0; j < this.plants.length; j++) {
        let plant = this.plants[j];
        geneArr.push(plant[gene]);
      }
      let geneCount = {};
      for (var i = 0; i < geneArr.length; i++) {
        if (geneArr[i] == "W" || geneArr[i] == "X") {
          geneCount[geneArr[i]] = Number((Math.round((1 + (geneCount[geneArr[i]] || 0))*10)/10).toFixed(1));
        } else {
          geneCount[geneArr[i]] = Number((Math.round((0.6 + (geneCount[geneArr[i]] || 0))*10)/10).toFixed(1));
        }
      }
      let maxVal = 0;
      let maxKey = "";
      for(var obj in geneCount) {
        if(geneCount[obj] > maxVal) {
          maxKey = obj.toString();
          maxVal = geneCount[obj];
        } else if(geneCount[obj] == maxVal) {
          maxKey += "/" + obj;
        }
      }
      return maxKey;
    },
  },
  computed: {
    geneObj () {
      return this.$store.state.geneSelect;
    },
    plants () {
      return this.$store.state.savedPlants;
    },
  },
};
</script>


<style lang="scss" scoped>
.md-app {
  min-height: 940px;
  border: 1px solid rgba(#000, 0.12);
}

.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-content {
  padding: 16px;
}

.nav-icon {
  margin-right: 0px;
}

.gene-button {
  height: 60px;
  width: 60px;
  font-size: 20px;
  margin-bottom: 10px;
}

.geneButtons {
  max-width: 460px;
  margin-right: 40px;
}

.mainDiv {
  max-width: 99%;
}

.sim-button {
  max-width: 120px;
}
</style>