<template>
  <div class="page-container md-layout-column">
    <md-app>
      <md-app-toolbar>
        <md-button
          class="md-icon-button"
          @click="toggleMenu"
          v-if="!showNavigation"
        >
          <md-icon>menu</md-icon>
        </md-button>
        <span class="md-title">Rust Breeding Calculator</span>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="showNavigation" md-persistent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Menu</span>
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>
        <navigation-bar></navigation-bar>
      </md-app-drawer>

      <md-app-content>
        {{this.test}}
      </md-app-content>
    </md-app>
  </div>
</template>


<script>
import NavigationBar from "../components/NavigationBar.vue";

export default {
  name: "tutorialPage",
  data: () => ({
    showNavigation: true,
  }),
  components: {
    NavigationBar,
  },
  methods: {
    toggleMenu() {
      this.showNavigation = !this.showNavigation;
    },
  },
  computed: {
    test () {
      return this.$store.state.testState;
    }
  },
};
</script>


<style lang="scss" scoped>
.md-app {
  min-height: 940px;
  border: 1px solid rgba(#000, 0.12);
}

.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-content {
  padding: 16px;
}

.nav-icon {
  margin-right: 0px;
}

.gene-button {
  height: 60px;
  width: 60px;
  font-size: 20px;
  margin-bottom: 10px;
}

.geneButtons {
  max-width: 460px;
  margin-right: 40px;
}

.mainDiv {
  max-width: 99%;
}

.sim-button {
  max-width: 120px;
}
</style>