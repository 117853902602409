<template>
  <div>
    <md-card>
      <md-card-content
        class="gene-card md-layout md-gutter md-alignment-top-space-between"
      >
        <div class="md-layout-item">
          <table>
            <tr>
              <td>
                <md-button
                  v-if="gene.gene1 == 'G'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  G
                </md-button>
                <md-button
                  v-if="gene.gene1 == 'Y'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  Y
                </md-button>
                <md-button
                  v-if="gene.gene1 == 'H'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  H
                </md-button>
                <md-button
                  v-if="gene.gene1 == 'X'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  X
                </md-button>
                <md-button
                  v-if="gene.gene1 == 'W'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  W
                </md-button>
              </td>
              <td>
                <md-button
                  v-if="gene.gene2 == 'G'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  G
                </md-button>
                <md-button
                  v-if="gene.gene2 == 'Y'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  Y
                </md-button>
                <md-button
                  v-if="gene.gene2 == 'H'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  H
                </md-button>
                <md-button
                  v-if="gene.gene2 == 'X'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  X
                </md-button>
                <md-button
                  v-if="gene.gene2 == 'W'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  W
                </md-button>
              </td>
              <td>
                <md-button
                  v-if="gene.gene3 == 'G'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  G
                </md-button>
                <md-button
                  v-if="gene.gene3 == 'Y'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  Y
                </md-button>
                <md-button
                  v-if="gene.gene3 == 'H'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  H
                </md-button>
                <md-button
                  v-if="gene.gene3 == 'X'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  X
                </md-button>
                <md-button
                  v-if="gene.gene3 == 'W'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  W
                </md-button>
              </td>
              <td>
                <md-button
                  v-if="gene.gene4 == 'G'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  G
                </md-button>
                <md-button
                  v-if="gene.gene4 == 'Y'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  Y
                </md-button>
                <md-button
                  v-if="gene.gene4 == 'H'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  H
                </md-button>
                <md-button
                  v-if="gene.gene4 == 'X'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  X
                </md-button>
                <md-button
                  v-if="gene.gene4 == 'W'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  W
                </md-button>
              </td>
              <td>
                <md-button
                  v-if="gene.gene5 == 'G'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  G
                </md-button>
                <md-button
                  v-if="gene.gene5 == 'Y'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  Y
                </md-button>
                <md-button
                  v-if="gene.gene5 == 'H'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  H
                </md-button>
                <md-button
                  v-if="gene.gene5 == 'X'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  X
                </md-button>
                <md-button
                  v-if="gene.gene5 == 'W'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  W
                </md-button>
              </td>
              <td>
                <md-button
                  v-if="gene.gene6 == 'G'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  G
                </md-button>
                <md-button
                  v-if="gene.gene6 == 'Y'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  Y
                </md-button>
                <md-button
                  v-if="gene.gene6 == 'H'"
                  class="gene-button md-icon-button md-raised md-primary"
                >
                  H
                </md-button>
                <md-button
                  v-if="gene.gene6 == 'X'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  X
                </md-button>
                <md-button
                  v-if="gene.gene6 == 'W'"
                  class="gene-button md-icon-button md-raised md-accent"
                >
                  W
                </md-button>
              </td>
            </tr>
          </table>
        </div>
        <div class="md-layout-item close-button">
          <md-button class="md-icon-button" @click="deletePlant()">
            <md-icon>close</md-icon>
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>


<script>
export default {
  name: "PlantGenes",
  props: {
    gene: {},
    index: Number,
  },
  methods: {
    deletePlant() {
      this.$store.commit("deletePlant",this.index);
    }
  },
};
</script>


<style lang="scss" scoped>
.gene-button {
  height: 60px;
  width: 60px;
  font-size: 20px;
  margin-bottom: 10px;
}

.gene-card {
  padding-top: 12px;
  padding-bottom: 12px !important;
}

.md-card {
  display: inline-block;
  vertical-align: top;
  align-items: space-between;
  width: 600px;
}

.close-button {
  max-width: 80px;
}
</style>
