<template>
  <table>
    <tr>
      <th>1</th>
      <th>2</th>
      <th>3</th>
      <th>4</th>
      <th>5</th>
      <th>6</th>
    </tr>

    <tr>
      <td>
        <md-button
          @click="geneSelect(1, 'G')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(1, 'G') }"
        >
          G
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(2, 'G')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(2, 'G') }"
        >
          G
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(3, 'G')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(3, 'G') }"
        >
          G
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(4, 'G')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(4, 'G') }"
        >
          G
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(5, 'G')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(5, 'G') }"
        >
          G
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(6, 'G')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(6, 'G') }"
        >
          G
        </md-button>
      </td>
    </tr>

    <tr>
      <td>
        <md-button
          @click="geneSelect(1, 'Y')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(1, 'Y') }"
        >
          Y
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(2, 'Y')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(2, 'Y') }"
        >
          Y
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(3, 'Y')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(3, 'Y') }"
        >
          Y
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(4, 'Y')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(4, 'Y') }"
        >
          Y
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(5, 'Y')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(5, 'Y') }"
        >
          Y
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(6, 'Y')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(6, 'Y') }"
        >
          Y
        </md-button>
      </td>
    </tr>

    <tr>
      <td>
        <md-button
          @click="geneSelect(1, 'H')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(1, 'H') }"
        >
          H
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(2, 'H')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(2, 'H') }"
        >
          H
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(3, 'H')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(3, 'H') }"
        >
          H
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(4, 'H')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(4, 'H') }"
        >
          H
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(5, 'H')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(5, 'H') }"
        >
          H
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(6, 'H')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-primary': renderGene(6, 'H') }"
        >
          H
        </md-button>
      </td>
    </tr>

    <tr>
      <td>
        <md-button
          @click="geneSelect(1, 'X')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(1, 'X') }"
        >
          X
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(2, 'X')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(2, 'X') }"
        >
          X
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(3, 'X')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(3, 'X') }"
        >
          X
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(4, 'X')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(4, 'X') }"
        >
          X
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(5, 'X')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(5, 'X') }"
        >
          X
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(6, 'X')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(6, 'X') }"
        >
          X
        </md-button>
      </td>
    </tr>

    <tr>
      <td>
        <md-button
          @click="geneSelect(1, 'W')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(1, 'W') }"
        >
          W
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(2, 'W')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(2, 'W') }"
        >
          W
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(3, 'W')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(3, 'W') }"
        >
          W
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(4, 'W')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(4, 'W') }"
        >
          W
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(5, 'W')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(5, 'W') }"
        >
          W
        </md-button>
      </td>
      <td>
        <md-button
          @click="geneSelect(6, 'W')"
          class="gene-button md-icon-button md-raised"
          v-bind:class="{ 'md-accent': renderGene(6, 'W') }"
        >
          W
        </md-button>
      </td>
    </tr>
  </table>
</template>


<script>
export default {
  name: "GeneSelect",
  props: {},
  data: () => ({}),
  methods: {
    geneSelect(key, value) {
      let payload = { key: "gene" + key, val: value };
      this.$store.commit("changeSelectGene", payload);
    },
    renderGene(index, gene) {
      let curGene = "gene" + index;
      if (this.geneObj[curGene] == gene || this.geneObj[curGene] == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    geneObj() {
      return this.$store.state.selectGene;
    },
  },
};
</script>


<style lang="scss" scoped>
.gene-button {
  height: 60px;
  width: 60px;
  font-size: 20px;
  margin-bottom: 10px;
}
</style>
