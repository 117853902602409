import VueRouter from "vue-router";
import startPage from "./pages/startPage";
import automaticPage from "./pages/automaticPage";
import tutorialPage from "./pages/tutorialPage";


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: startPage
        },
        {
            path: '/rust-breeder',
            component: startPage
        },
        {
            path: '/automatic',
            component: automaticPage
        },
        {
            path: '/tutorial',
            component: tutorialPage
        },
    ]
});


export default router
