<template>
  <div>
    <md-card>
      <md-card-header v-if="pairType !== ''">
        <div class="md-subheading">{{ pairType }}</div>
      </md-card-header>
      <md-card-content class="gene-card">
        <div class="md-layout md-gutter">
          <div class="md-layout-item res-gene">
            <table>
              <tr>
                <td>
                  <md-button
                    v-if="gene.gene1 !== ''"
                    class="gene-button md-icon-button md-raised"
                    v-bind:class="{
                      'md-primary': !renderColor(1),
                      'md-accent': renderColor(1),
                    }"
                  >
                    {{ gene.gene1 }}
                  </md-button>
                  <md-button
                    v-if="gene.gene2 !== ''"
                    class="gene-button md-icon-button md-raised"
                    v-bind:class="{
                      'md-primary': !renderColor(2),
                      'md-accent': renderColor(2),
                    }"
                  >
                    {{ gene.gene2 }}
                  </md-button>
                  <md-button
                    v-if="gene.gene3 !== ''"
                    class="gene-button md-icon-button md-raised"
                    v-bind:class="{
                      'md-primary': !renderColor(3),
                      'md-accent': renderColor(3),
                    }"
                  >
                    {{ gene.gene3 }}
                  </md-button>
                  <md-button
                    v-if="gene.gene4 !== ''"
                    class="gene-button md-icon-button md-raised"
                    v-bind:class="{
                      'md-primary': !renderColor(4),
                      'md-accent': renderColor(4),
                    }"
                  >
                    {{ gene.gene4 }}
                  </md-button>
                  <md-button
                    v-if="gene.gene5 !== ''"
                    class="gene-button md-icon-button md-raised"
                    v-bind:class="{
                      'md-primary': !renderColor(5),
                      'md-accent': renderColor(5),
                    }"
                  >
                    {{ gene.gene5 }}
                  </md-button>
                  <md-button
                    v-if="gene.gene6 !== ''"
                    class="gene-button md-icon-button md-raised"
                    v-bind:class="{
                      'md-primary': !renderColor(6),
                      'md-accent': renderColor(6),
                    }"
                  >
                    {{ gene.gene6 }}
                  </md-button>
                </td>
              </tr>
            </table>
          </div>
          <div class="md-layout-item">
            <pair-input-gene
              v-for="pair in pairs"
              v-bind:gene="pair"
              v-bind:key="pair.id"
            ></pair-input-gene>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>


<script>
import PairInputGene from "./PairInputGene.vue";

export default {
  name: "AutomaticPairs",
  components: {
    PairInputGene,
  },
  props: {
    pairType: String,
    generation: Number,
    gene: {
      gene1: "",
      gene2: "",
      gene3: "",
      gene4: "",
      gene5: "",
      gene6: "",
    },
    pairs: [],
  },
  data: () => ({}),
  methods: {
    renderColor(index) {
      let curGeneID = "gene" + index;
      let curGene = this.gene[curGeneID];
      if (curGene.indexOf("W") !== -1 || curGene.indexOf("X") !== -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.gene-button {
  height: 60px;
  width: 60px;
  font-size: 20px;
  margin-bottom: 10px;
}

.gene-card {
  padding-top: 12px;
  padding-bottom: 12px !important;
}

.res-gene {
  min-width: 450px;
}
</style>
