<template>
  <div>
    <table>
      <tr>
        <td>
          <md-button
            v-if="gene.gene1 !== ''"
            class="gene-button md-icon-button md-raised"
            v-bind:class="{
              'md-primary': !renderColor(1),
              'md-accent': renderColor(1),
            }"
          >
            {{ gene.gene1 }}
          </md-button>
          <md-button
            v-if="gene.gene2 !== ''"
            class="gene-button md-icon-button md-raised"
            v-bind:class="{
              'md-primary': !renderColor(2),
              'md-accent': renderColor(2),
            }"
          >
            {{ gene.gene2 }}
          </md-button>
          <md-button
            v-if="gene.gene3 !== ''"
            class="gene-button md-icon-button md-raised"
            v-bind:class="{
              'md-primary': !renderColor(3),
              'md-accent': renderColor(3),
            }"
          >
            {{ gene.gene3 }}
          </md-button>
          <md-button
            v-if="gene.gene4 !== ''"
            class="gene-button md-icon-button md-raised"
            v-bind:class="{
              'md-primary': !renderColor(4),
              'md-accent': renderColor(4),
            }"
          >
            {{ gene.gene4 }}
          </md-button>
          <md-button
            v-if="gene.gene5 !== ''"
            class="gene-button md-icon-button md-raised"
            v-bind:class="{
              'md-primary': !renderColor(5),
              'md-accent': renderColor(5),
            }"
          >
            {{ gene.gene5 }}
          </md-button>
          <md-button
            v-if="gene.gene6 !== ''"
            class="gene-button md-icon-button md-raised"
            v-bind:class="{
              'md-primary': !renderColor(6),
              'md-accent': renderColor(6),
            }"
          >
            {{ gene.gene6 }}
          </md-button>
        </td>
      </tr>
    </table>
  </div>
</template>


<script>
export default {
  name: "PairInputGene",
  props: {
    gene: {
      gene1: "",
      gene2: "",
      gene3: "",
      gene4: "",
      gene5: "",
      gene6: "",
    },
  },
  data: () => ({}),
  methods: {
    renderColor(index) {
      let curGeneID = "gene" + index;
      let curGene = this.gene[curGeneID];
      if (curGene.indexOf("W") !== -1 || curGene.indexOf("X") !== -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.gene-button {
  height: 40px;
  width: 30px;
  font-size: 12px;
  margin-bottom: 0px;
}

.gene-card {
  padding-top: 12px;
  padding-bottom: 12px !important;
}
</style>
