<template>
  <md-list>
    <md-list-item>
      <md-icon class="nav-icon">help</md-icon>
      <md-button to="/tutorial" class="md-list-item-text" disabled>
        <span class="md-list-item-text">Tutorial</span>
      </md-button>
    </md-list-item>

    <md-list-item>
      <md-icon class="nav-icon">biotech</md-icon>
      <md-button class="md-list-item-text" disabled>
        <span class="md-list-item-text">Theory</span>
      </md-button>
    </md-list-item>

    <md-list-item>
      <md-icon class="nav-icon">calculate</md-icon>
      <md-button to="/rust-breeder" class="md-list-item-text">
        <span class="md-list-item-text">Calculator</span>
      </md-button>
    </md-list-item>

    <md-list-item>
      <md-icon class="nav-icon">precision_manufacturing</md-icon>
      <md-button to="/automatic" class="md-list-item-text">
        <span class="md-list-item-text">Automatic</span>
      </md-button>
    </md-list-item>
  </md-list>
</template>


<script>
export default {
  name: "NavigationBar",
  props: {},
  data: () => ({
    
  }),
  methods: {
    
  },
};
</script>


<style lang="scss" scoped>
</style>
