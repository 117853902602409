import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectGene: {
      gene1: "",
      gene2: "",
      gene3: "",
      gene4: "",
      gene5: "",
      gene6: "",
    },
    savedPlants: [],
    testState: "teststate works",
  },
  mutations: {
    changeSelectGene(state, keyValue) {
      state.selectGene[keyValue["key"]] = keyValue["val"];
    },
    saveSelectGene(state) {
      const savedGene = JSON.parse(JSON.stringify(state.selectGene));
      if (
        savedGene.gene1 !== "" &&
        savedGene.gene2 !== "" &&
        savedGene.gene3 !== "" &&
        savedGene.gene4 !== "" &&
        savedGene.gene5 !== "" &&
        savedGene.gene6 !== ""
      ) {
        state.savedPlants.unshift(savedGene);
        this.commit("resetSelectGene");
      }
    },
    resetSelectGene(state) {
      state.selectGene = {
        gene1: "",
        gene2: "",
        gene3: "",
        gene4: "",
        gene5: "",
        gene6: "",
      };
    },
    deletePlant(state, index) {
      state.savedPlants.splice(index,1);
    },
    initializeStore(state) {
      if (localStorage.getItem("store")) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("store")))
        );
      }
    },
  },
});
